// utils/dataProcessing.ts
import { AcquisitionDataPoint, ProcessedDataPoint } from '@/types/feed_processing_datatypes';

// Interface for additional conversion parameters
interface CartesianConversionParams {
  sensorXOffset: number;
  angleOffset: number;
  pivotingRadius: number;
  sensorHeight: number;
}

interface RawData {
  distance: number[];
  signal_strength: number[];
  temperature: number[];
  imu_angle_x: number[];
  imu_angle_y: number[];
  imu_angle_z: number[];
  servo_angle: number[];
}


export const restructureAcquisitionData = (rawData: RawData): AcquisitionDataPoint[] => {
  if (rawData && Array.isArray(rawData.distance)) {
    return rawData.distance.map((_: number, index: number) => ({
      distance: rawData.distance[index],
      signal_strength: rawData.signal_strength[index],
      temperature: rawData.temperature[index],
      imu_angle_x: rawData.imu_angle_x[index],
      imu_angle_y: rawData.imu_angle_y[index],
      imu_angle_z: rawData.imu_angle_z[index],
      servo_angle: rawData.servo_angle[index],
    }));
  } else {
    console.error("Raw data is not in the expected format:", rawData);
    return [];
  }
};

// Process acquisition data and apply IMU corrections
export const processAcquisitionData = (
  acquisitionData: AcquisitionDataPoint[],
  imuAngularCorrection: number,
  imuAngleOffset: number
): AcquisitionDataPoint[] => {
  return acquisitionData.map(dataPoint => ({
    ...dataPoint,
    imu_angle_x: (dataPoint.imu_angle_x * imuAngularCorrection) + imuAngleOffset,
    // Apply similar corrections to other IMU angles if needed
  }));
};

// Convert polar coordinates to Cartesian coordinates
export const convertToCartesian = (
  processedData: AcquisitionDataPoint[],
  { sensorXOffset, angleOffset, pivotingRadius, sensorHeight }: CartesianConversionParams
): ProcessedDataPoint[] => {
  return processedData.map(dataPoint => {
    const angleInRadians = (Math.PI / 180) * (dataPoint.imu_angle_x - angleOffset);
    return {
      ...dataPoint,
      x: sensorXOffset + Math.sin(angleInRadians) * (dataPoint.distance + pivotingRadius),
      y: sensorHeight - Math.cos(angleInRadians) * (dataPoint.distance + pivotingRadius),
    };
  });
};

export const processAndConvertAcquisitionData = (
  rawData: RawData,
  imuAngularCorrection: number,
  imuAngleOffset: number,
  conversionParams: CartesianConversionParams
): ProcessedDataPoint[] => {
  const restructuredData = restructureAcquisitionData(rawData);
  const processedData = processAcquisitionData(restructuredData, imuAngularCorrection, imuAngleOffset);
  return convertToCartesian(processedData, conversionParams);
};